import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = makeStyles((theme) => ({
  placeholder: {
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    fontSize: 14,
    whiteSpace: "nowrap",
    color: "#98A0AC",
  },
  // div: {
  //     min: "300px"
  // },
  title: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: 12,
    color: "#4E5A6B",
    marginBottom: "8px",
  },
  type: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: 12,
    color: "#4E5A6B",
    backgroundColor: "#F2F4F7",
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    textAlign: "center",
    marginRight: "4px",
    marginLeft: "4px",
    cursor: "pointer",
  },
  textField: {
    width: "70px",
    padding: "4px",
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : 32),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  dialog: {
    "& .MuiPopover-paper": {
      borderRadius: theme.palette.borderRadius,
      boxShadow: "0px 8px 24px #0717411F",
    },
  },
  typea: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: 12,

    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    textAlign: "center",
    marginRight: "4px",
    marginLeft: "4px",
    cursor: "pointer",
    backgroundColor: "#5078E1",
    color: "white",
  },
  value: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: 14,
    color: "#091B29",
  },
  main: {
    border: "1px solid #CED3DD",
    borderRadius: "4px",
  },
  Label: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "crayond_semiBold",
    fontSize: "16px",
    marginBottom: "5px",
    marginTop: "15px",
  },
}));
export const DatePickerNew = ({
  startIcon = "",
  placeholder = "",
  handleChange = () => false,
  value = "",
  label = null,
  isrequired,
  isError = "",
  errorMessage = "",
  isReadonly = false,
  range = false,
  rangeValue = "",
  endDate = "",
}) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (!isReadonly) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      {
        label && <Typography className={classes.Label} noWrap>{label}
          {isrequired && (
            <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
              *
            </Typography>
          )}
        </Typography>
      }
      <div className={classes?.main}>
        <Box
          sx={{ cursor: "pointer", position: "relative", padding: "10px" }}
          display="flex"
          alignItems="center"
          height="43px"
          onClick={handleClick}
        >
          <Box flexGrow={1} marginLeft="4px">
            {value ? (
              range ? (
                <Typography className={classes.value}>
                  {moment(value).format("DD MMM YY")} -{" "}
                  {moment(endDate).format("DD MMM YY")} (
                  {moment(endDate).diff(moment(value), "days")} Days)
                </Typography>
              ) : (
                <Typography className={classes.value}>
                  {moment(value).format("DD MMM YY")}
                </Typography>
              )
            ) : (
              <Typography className={classes.placeholder}>
                {placeholder}
              </Typography>
            )}
          </Box>
          <Box marginTop="4px" marginRight="8px">
            <img src={"/images/icons8-calendar (5).svg"} alt="" />
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.dialog}
        >
          <div className={classes.div}>
            <Box p={1.4}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <div>
                  <Typography className={classes.title}>{label}</Typography>

                  <DatePicker
                    //  dateFormat="MM-DD-YYYY"
                    selected={value}
                    onChange={(value) => {
                      handleChange(value);
                      setAnchorEl(null);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    disabled
                    dropdownMode="select"
                    selectsDisabledDaysInRange
                    inline
                    //showMonthDropdown
                  />
                </div>
              </Stack>
            </Box>
          </div>
        </Popover>
      </div>
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
