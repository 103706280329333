import React from "react";
import { CunstomertableJson, Billing } from "./common";

import {
  TopNavBar,
  TableComponent,
  ChooseCustomerBox,
  DrawerComponent,
  PricingDetails,
} from "../../components";

import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  InputLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";

import { useStyles } from "./style";

import SearchIcon from "@mui/icons-material/Search";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { NetworkCall } from "../../networkcall";

import { NetWorkCallMethods } from "../../utils";

import { config } from "../../config";

import { BackdropContext, AlertContext } from "../../contexts";

import { AlertProps } from "../../utils";

import { useHistory } from "react-router-dom";

import { Routes } from "../../router/routes";

import { useTranslation } from "react-i18next";
import { UseDebounce } from "../../components/customHooks/useDebounce";

const IntialState = {
  plan_id: "",
  plan_name: "",
  plan_options: {},
  addon_options: {
    Monthly: 0,
    Yearly: 0,
  },
  addons: [],
  billing_type: "",
  plan_reflect_from: "",
};

export const Subscription = (props) => {
  // use classes
  const classes = useStyles();
  const history = useHistory();
  // end

  const { t } = useTranslation(["topBar"]);

  const [action, setAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [tableData, setTabledata] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const [jsondata, setJsondata] = React.useState({
    main: [],
    selected: [],
    selectedDetail: [],
    addonOptions: [],
    planOptions: [],
  });
  const [data, setData] = React.useState({ ...IntialState });
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);


  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const alert = React.useContext(AlertContext);

  // drawer state
  const [mapsubcription, setmapsubcription] = React.useState(false);

  const showAction = (action, e) => {
    setAction(action?.length > 0 ? true : false);
    history.push({
      pathname: Routes.createSubscription,
      state: {
        isEdit: true,
        data: action?.[0],
      },
    });
  };

  const updateState = async (key, value, label, option) => {
    if (key === "addons") {
      const first = value;
      let Monthly = option?.Monthly;
      let Yearly = option?.Yearly;

      let amount = {};

      let unchecked = [...data.addons];
      if (data.addons.includes(first)) {
        for (var i = 0; i < unchecked.length; i++) {
          if (unchecked[i] === first) {
            unchecked.splice(i, 1);
          }
        }
        amount.Monthly = data?.addon_options?.Monthly - Monthly;
        amount.Yearly = data?.addon_options?.Yearly - Yearly;
      } else {
        unchecked.push(value);
        amount.Monthly = Monthly + data?.addon_options?.Monthly;
        amount.Yearly = Yearly + data?.addon_options?.Yearly;
      }

      setData({ ...data, addons: unchecked, addon_options: amount });
    } else if (key === "plan_id") {
      getAddons(value);
      setData({
        ...data,
        [key]: value,
        plan_name: label,
        plan_options: option,
      });
    } else {
      setData({ ...data, [key]: value });
    }
  };

  // modal open function
  const handleOpen = () => {
    setOpen(true);
    getCustomers();
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Customer List Handle
  const handleData = (data) => {
    const first = data.id;
    let Id = [];

    if (jsondata?.selected?.includes(first)) {
      let unchecked = [...jsondata.selected];
      for (var i = 0; i < unchecked.length; i++) {
        if (unchecked[i] === first) {
          unchecked.splice(i, 1);
        }
      }
    } else {
      Id.push(data.id);
    }

    let detailData = [];
    detailData.push(data);

    setJsondata({ ...jsondata, selected: Id, selectedDetail: detailData });
  };

  // Get table all
  const getAll = (search, filter, offset) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter,
      limit: pageSize,
      offset: offset,
    };

    NetworkCall(
      `${config.api_rest}api/v1/subscription/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setTabledata(main);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };

  React.useEffect(() => {
    getAll("", switchActive, 0);
    // eslint-disable-next-line
  }, [switchActive, pageSize]);

  // next Mapsupscription function
  const nextMapsupscription = async () => {
    if (jsondata?.selected?.length > 0) {
      setOpen(false);
      setmapsubcription(!mapsubcription);
      getPlan();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Select any User",
      });
    }
  };

  // On Edit
  const onEdit = (cell, param, e) => {
    e.stopPropagation();
    history.push({
      pathname: Routes.createSubscription,
      state: {
        isEdit: true,
        data: param.row.id,
      },
    });
  };

  // Get Customers
  const getCustomers = () => {
    const payload = {
      limit: 9999999999,
      offset: 0
    }
    NetworkCall(
      `${config.api_rest}api/v1/customer/get/all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let data = [];
        main?.data?.rows?.map((val, index) => {
          let obj = {};
          obj.id = val.id;
          obj.customername = val.company_name;
          obj.email = val.company_mail_id;
          obj.phonenumber = val.business_phone;
          obj.ontrail = val.on_trial;
          obj.active = val.is_active;
          data.push(obj);
        });
        setJsondata({ ...jsondata, main: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // On Confirm
  const confirm = () => {
    let NewData = {
      unique_id: localStorage.getItem("profileId"),
      company_id: jsondata?.selectedDetail[0].id,
      email_id: jsondata?.selectedDetail[0].email,
      mobile_no: jsondata?.selectedDetail[0].phonenumber,
      plan_id: data?.plan_id,
      billing_type: data?.billing_type,
      addons: data?.addons,
      plan_amount: data?.plan_options[data?.billing_type],
      addon_amount: data?.addon_options[data?.billing_type],
      total_amount: isNaN(
        data?.plan_options[data?.billing_type] +
        data?.addon_options[data?.billing_type]
      )
        ? 0
        : data?.plan_options[data?.billing_type] +
        data?.addon_options[data?.billing_type],
    };
    if (NewData.plan_id !== "") {
      setLoading(true);
      NetworkCall(
        `${config.api_rest}api/v1/subscription/new`,
        NetWorkCallMethods.post,
        NewData,
        null,
        true,
        false
      )
        .then((response) => {
          setLoading(false);
          getAll("", switchActive, 0);
        })
        .finally(() => {
          setmapsubcription(false);
        });
    } else {
      setLoading(false);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Select any Data",
      });
    }
  };

  // Get Add-ons
  const getAddons = (Id) => {
    NetworkCall(
      `${config.api_rest}api/v1/plan/get`,
      NetWorkCallMethods.post,
      {
        plan_id: Id,
      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let options = [];
        main?.data?.plan_addons?.map((val) => {
          let obj = {};
          obj.id = val.id;
          obj.name = val?.add_on?.name;
          obj.options = {
            Monthly: val.monthly_price,
            Yearly: val.yearly_price,
          };
          options.push(obj);
        });
        setJsondata({ ...jsondata, addonOptions: options });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  Get Plans
  const getPlan = () => {
    const payload = {
      limit: 9999999999,
      offset: 0
    }
    NetworkCall(
      `${config.api_rest}api/v1/plan/get/all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let options = [];
        main?.data?.map((val) => {
          let obj = {};
          obj.value = val.id;
          obj.label = val.name;
          obj.options = {
            Monthly: val.monthly_fees,
            Yearly: val.yearly_fees,
          };
          options.push(obj);
        });
        setJsondata({ ...jsondata, planOptions: options });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowclick = (param) => {
    // history.push({
    //   pathname: Routes.subscriptiondetails,
    //   state: {
    //     isEdit: true,
    //     data: param.row.id,
    //   },
    // });
  };


  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getAll("", switchActive, offset)
  };


  const onSubscriptionSearch = (search = "") => {


    setSearchkey(search)
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAll(e, switchActive, 0);

  };
  return (
    <div>
      {/* Topbar bar */}

      <TopNavBar
        title={t("subscription")}
        isSearch
        buttonLabelonSubscriptionSearch="Create"
        isAction
        buttonLabel="Create"
        isFilter
        functions={() => history.push(Routes.createSubscription)}
        action={action}
        onChangeSearch={onSubscriptionSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      {/* end */}

      {/* content */}

      <div className={classes.root}>
        {/* table section */}
        <TableComponent
          dataList={tableData?.rows ?? []}
          Header={CunstomertableJson(onEdit)}
          isChecked
          showAction={showAction}
          cellClick={(rowData) => handleRowclick(rowData)}
          totalCount={tableData?.count}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => handlePagination(newPage)}
        />

        {/* choose customer Drawer section */}
        <DrawerComponent
          DrawerClass={classes.Drawer}
          open={open}
          toggleDrawer={handleClose}
          onClose={handleClose}
          component={
            <div className={classes.drawerwidth}>
              <Box className={classes.Header}>
                <Box className={classes.closeIcondrawermap}>
                  <Typography variant="h6" onClick={handleClose}>
                    <CancelRoundedIcon />
                  </Typography>
                </Box>
                <Typography variant="h6" className={classes.title} noWrap>
                  Choose a customer
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={nextMapsupscription}
                    className={classes.drawerButtonTittle}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
              <div className={classes.customerCard}>
                {/* search input field */}
                <TextField
                  size="small"
                  type="search"
                  fullWidth
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  placeholder="search for a customer"
                  className={classes.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <ChooseCustomerBox
                  data={jsondata?.main}
                  search={search}
                  editData={handleData}
                  selectedData={jsondata?.selected}
                />
              </div>
            </div>
          }
        />

        {/* end */}

        {/* map supscription  drawer sectiobn */}
        <DrawerComponent
          DrawerClass={classes.Drawer}
          open={mapsubcription}
          toggleDrawer={nextMapsupscription}
          onClose={nextMapsupscription}
          component={
            <div>
              <Box className={classes.drawerwidth}>
                <Box className={classes.Header}>
                  <Box className={classes.closeIcondrawermap}>
                    <Typography variant="h6" onClick={nextMapsupscription}>
                      <CancelRoundedIcon />
                    </Typography>
                  </Box>

                  <Typography variant="h6" className={classes.title} noWrap>
                    Map subscription
                  </Typography>

                  <Box>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={confirm}
                      className={classes.drawerButtonTittle}
                      disabled={loading}
                    >
                      {loading ? "Confirming..." : "Confirm"}
                    </Button>
                  </Box>
                </Box>
              </Box>

              <div className={classes.createnewsection}>
                <InputLabel className={classes.drawerlabel} noWrap>
                  Choose a plan<sup>*</sup>
                </InputLabel>

                <Autocomplete
                  className={classes.menu}
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  value={data?.plan_name}
                  options={jsondata?.planOptions}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(e, newValue) =>
                    updateState(
                      "plan_id",
                      newValue.value,
                      newValue.label,
                      newValue.options
                    )
                  }
                />

                <InputLabel className={classes.drawerlabel} noWrap>
                  Choose billing type<sup>*</sup>
                </InputLabel>

                <Autocomplete
                  className={classes.menu}
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  value={data?.billing_type}
                  options={Billing}
                  onChange={(e, newValue) =>
                    updateState("billing_type", newValue.label)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />

                <Box className={classes.addonSectionTittle}>
                  <Typography variant="h6">Add-ons</Typography>
                  <Typography>Check to select</Typography>
                </Box>

                {jsondata?.addonOptions?.map((v, i) => {
                  return (
                    <Box className={classes.addonSection}>
                      <Typography>{v.name}</Typography>
                      <Checkbox
                        color="primary"
                        checked={data?.addons?.some((x) => x === v.id)}
                        onChange={(e) =>
                          updateState("addons", v.id, null, v.options)
                        }
                      />
                    </Box>
                  );
                })}

                {/* Pricing details section */}

                <div className={classes.pricingDetailsMain}>
                  <Box>
                    <Typography className={classes.customerDetailTittle}>
                      Customer details
                    </Typography>

                    {/* choose customer card */}
                    <ChooseCustomerBox
                      data={jsondata?.selectedDetail}
                      search={search}
                    // editData={handleData}
                    />
                  </Box>

                  {/* <PricingDetails
                    plantittle={"Plan"}
                    planename={"Silver"}
                    user={"Per User"}
                    yearly={"Yearly"}
                    planamount={"$244"}
                    addon={"Add-ons"}
                    addno={"01"}
                    Report={"Reports"}
                    addamount={"$2"}
                    totalamount={"$246"}
                    cuserDetailshide={false}
                    heading={"Previous plan"}
                  /> */}

                  <PricingDetails
                    plantittle={"Plan"}
                    planename={data?.plan_name}
                    user={"Per User"}
                    yearly={data?.billing_type}
                    planamount={data?.plan_options[data?.billing_type]}
                    addon={"Add-ons"}
                    addno={data?.addons?.length}
                    Report={"Reports"}
                    addamount={data?.addon_options[data?.billing_type]}
                    totalamount={
                      isNaN(
                        data?.plan_options[data?.billing_type] +
                        data?.addon_options[data?.billing_type]
                      )
                        ? 0
                        : data?.plan_options[data?.billing_type] +
                        data?.addon_options[data?.billing_type]
                    }
                    cuserDetailshide={false}
                    heading={"New plan"}
                  />

                  <Box className={classes.planrefelect}>
                    <Typography>Plan reflect from</Typography>
                    {["Now", "Next Billing"]?.map((v) => {
                      return (
                        <div>
                          <Checkbox
                            color="primary"
                            onClick={() => updateState("plan_reflect_from", v)}
                          />{" "}
                          {v}
                        </div>
                      );
                    })}
                  </Box>
                </div>
              </div>
            </div>
          }
        />

        {/* end */}
      </div>
    </div>
  );
};
