import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { NetWorkCallMethods } from "./constants";

export const loadOptionsApis = async (
  endpoint,
  headers = null,
  queryVariables,
  search = "",
  array = [],
  handleLoading = null,
  extractLabel,
  mappingVariable = {},
  manualResponse,
  functionParams
) => {
  let result,
    offset = 0;

  if (search && !Boolean(array?.length)) {
    offset = 0;
  } else {
    offset = array?.length;
  }
  result = await networkCallBackApi(
    endpoint,
    headers,
    { ...queryVariables, search },
    handleLoading,
    offset
  );

  //Select options should be label,value pairs like ({label:'',value:''})
  if (mappingVariable?.label || mappingVariable?.value) {
    result[extractLabel] = result?.[extractLabel]?.map((_) => ({
      label: _?.[mappingVariable?.label ?? "label"],
      value: _?.[mappingVariable?.value ?? "value"],
    }));
  }

  //manually construct array
  if (manualResponse) {
    result[extractLabel] = manualResponse(result[extractLabel]);
  }

  return {
    options:functionParams?.all ? (offset === 0 ?
      [{ value: functionParams?.all_value ?? null, label: functionParams?.all_label ?? "All" }, ...result?.[extractLabel]] :
      [...result?.[extractLabel]])
    : [...result?.[extractLabel]],
    hasMore: array?.length + result?.[extractLabel]?.length < result?.count,
  };
};

const networkCallBackApi = async (
  endpoint,
  headers = null,
  variable = {},
  handleLoading,
  offset
) => {
  let payload = {
    ...variable,
    startPage: offset,
    maxRecords: 10,
    offset: offset,
    limit: 10
  };

  const options = await NetworkCall(
    `${endpoint}`,
    NetWorkCallMethods.post,
    payload,
    headers,
    true,
    false
  )
    .then((response) => {
      const main = {
        count: response?.data?.data?.count ?? response?.data?.count,
        data: response?.data?.data ?? response?.data,
      };

      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};
