import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useStyles } from "../styles";
import { SelectBox, TextBox } from "../../../components";
import { loadOptionsApis } from "../../../utils/asyncLoading";
import { config } from "../../../config";

const db_type = [
  {
    label: "Dedicated DB",
    value: "Dedicated DB",
  },
  {
    label: "Shared DB",
    value: "Shared DB",
  },
  {
    label: "Custom DB",
    value: "Custom DB",
  },
];

const customDBfields = (data) => {
  return[
    {
      label: "DB Name",
      value: data?.db_name,
      placeholder: "Enter DB Name",
      stateName: "db_name",
      type: "text",
      isError: data?.error?.db_name?.length > 0,
      errorMessage: data?.error?.db_name
    },
    {
      label: "DB Host",
      value: data?.db_host,
      placeholder: "Enter DB Host",
      stateName: "db_host",
      type: "text",
      isError: data?.error?.db_host?.length > 0,
      errorMessage: data?.error?.db_host
    },
    {
      label: "DB Username",
      value: data?.db_username,
      placeholder: "Enter DB Username",
      stateName: "db_username",
      type: "text",
      isError: data?.error?.db_username?.length > 0,
      errorMessage: data?.error?.db_username
    },
    {
      label: "DB Password",
      value: data?.db_password,
      placeholder: "Enter DB Password",
      stateName: "db_password",
      type: "text",
      isError: data?.error?.db_password?.length > 0,
      errorMessage: data?.error?.db_password
    },
    {
      label: "DB Port",
      value: data?.db_port,
      placeholder: "Enter DB Port",
      stateName: "db_port",
      type: "number",
      isError: data?.error?.db_port?.length > 0,
      errorMessage: data?.error?.db_port
    }
  ]
}

export const ClientDetails = ({ updateState = () => false, data = {} }) => {
  const classes = useStyles();

  const maualResponse = (array) => {
    const result = array?.data?.map((val) => {
      return {
        ...val,
        label: val?.name,
        value: val?.id,
      };
    });
    return result;
  };

  const maualResponseTenants = (array) => {
    const result = array?.data?.map((val) => {
      return {
        ...val,
        label: val?.db_name,
        value: val?.id,
      };
    });
    return result;
  };

  const maualResponseRDS = (array) => {
    const result = array?.rows?.map((val) => {
      return {
        ...val,
        label: val?.label,
        value: val?.id,
      };
    });
    return result;
  };

  return (
    <Box className={classes.box} p={2}>
      <Box height={`calc(100vh - 230px)`} overflow="auto">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <SelectBox
              isRequired
              label="Select Database Type"
              placeholder="Select Database Type"
              value={data?.db_type ?? ""}
              onChange={(value) => {
                updateState("db_type", value);
              }}
              options={db_type}
              isError={data?.error?.db_type?.length > 0}
              errorMessage={data?.error?.db_type}
            />
          </Grid>
          {data?.db_type?.value === "Dedicated DB" && (
            <Grid item xs={12} md={6} lg={6}>
              <SelectBox
                label="Select Data Base"
                placeholder="Select Data Base"
                value={data?.rds_details ?? ""}
                onChange={(value) => {
                  updateState("rds_details", value);
                }}
                isRequired
                isPaginate={true}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    `${config.api_rest}api/v1/rds_data_base/get/all`,
                    { "x-api-key": config.api_key },
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    maualResponseRDS,
                    {
                      all:true,
                      all_value:null,
                      all_label:"Default"
                    }
                    
                  )
                }
                isError={data?.error?.rds_details?.length > 0}
                errorMessage={data?.error?.rds_details}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={6}>
            <SelectBox
              label="Select Region"
              placeholder="Select Region"
              value={data?.region ?? ""}
              onChange={(value) => {
                updateState("region", value);
              }}
              isPaginate={true}
              isMulti
              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  `${config.property_automate_api}/pasm/regions`,
                  { "x-api-key": config.api_key },
                  {},
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  maualResponse
                )
              }
              isError={data?.error?.region?.length > 0}
              errorMessage={data?.error?.region}
            />
          </Grid>

          {data?.db_type?.value === "Shared DB" && (
            <Grid item xs={12} md={6} lg={6}>
              <SelectBox
                label="Select Data Base"
                placeholder="Select Data Base"
                value={data?.shared_db ?? ""}
                onChange={(value) => {
                  updateState("shared_db", value);
                }}
                isRequired
                isPaginate={true}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    `${config.property_automate_api}/pasm/tenants`,
                    { "x-api-key": config.api_key },
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},

                    maualResponseTenants
                  )
                }
                isError={data?.error?.shared_db?.length > 0}
                errorMessage={data?.error?.shared_db}
              />
            </Grid>
          )}

          <Grid item xs={data?.db_type?.value === "Custom DB" ? 12 : 6}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={data?.sync}
                  onChange={(e) => updateState("sync", !data.sync)}
                  color="primary"
                  value="Sync required or not"
                />
              }
              label="Sync required or not"
            />
          </Grid>

          {
            data?.db_type?.value === "Dedicated DB" &&
            <Grid item xs={6}>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    checked={data?.is_shared}
                    onChange={(e) => updateState("is_shared", !data.is_shared)}
                    color="primary"
                    value="Is this shared db?"
                  />
                }
                label="Is this shared db?"
              />
            </Grid>
          }

          {
            data?.db_type?.value === "Custom DB" &&
            <Grid item xs={12}>
            <Grid container spacing={2}>
              {
                customDBfields(data)?.map((val) => {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <TextBox
                        fullWidth={true}
                        isrequired
                        value={data?.name}
                        type={val.type}
                        label={val?.label}
                        placeholder={val?.placeholder}
                        errorMessage={val?.errorMessage}
                        isError={val?.isError}
                        onChange={(e) => updateState(val?.stateName, e.target.value)}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
            </Grid>
          }
        </Grid>
      </Box>
    </Box>
  );
};
