import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { PricingDetails, SelectBox } from "../../../components";
import { DatePickerNew } from "../../../components/datePicker/datePicker";
import { CustomerCard } from "../../../components/customerCard";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncLoading";
import { useStyles } from "../styles";

const billing_type = [
  {
    label: "Monthly",
    value: "monthly_fees",
    price: "monthly_price",
  },
  {
    label: "Yearly",
    value: "yearly_fees",
    price: "yearly_price",
  },
];

export const MapSubScription = ({
  updateState = () => false,
  data = {},
  isEdit = false,
}) => {
  const classes = useStyles();

  const [list, setList] = React.useState({
    addOn: [],
  });

  const maualResponse = (array) => {

    const result = array?.rows?.map((i) => {
      return {
        ...i,
        label: i?.name,
        value: i?.id,
      };
    });
    return result;
  };

  const getAddons = (value) => {
    NetworkCall(
      `${config.api_rest}api/v1/plan/get`,
      NetWorkCallMethods.post,
      {
        plan_id: value,
      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data?.data?.plan_addons?.map((val) => {
          return {
            ...val,
            label: val?.add_on?.name,
            value: val.id,
          };
        });
        setList({ ...list, addOn: main });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const moduleMaualResponse = (array) => {
    const details = array?.data?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };

  return (
    <Box>
      <Box className={classes.box} p={2}>
        <Box
          height={`calc(100vh - ${isEdit ? "180px" : "230px"})`}
          overflow="auto"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <SelectBox
                isReadOnly={isEdit}
                isRequired
                label="Select Plan"
                placeholder="Select Plan"
                value={data?.plan ?? ""}
                onChange={(value) => {
                  if (isEdit) {
                    updateState("plan", value, " ");
                  } else {
                    updateState(
                      "plan",
                      value,
                      "modules",
                      value?.selected_modules
                    );
                  }
                }}
                isPaginate={true}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    `${config.api_rest}api/v1/plan/get/all`,
                    {},
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    maualResponse
                  )
                }
                isError={data?.error?.plan?.length > 0}
                errorMessage={data?.error?.plan}
              />
            </Grid>

            {data?.plan?.length !== 0 && (
              <Grid item xs={12} md={6} lg={6}>
                <SelectBox
                  isRequired
                  label="Select Modules"
                  placeholder="Select Modules"
                  value={data?.modules ?? ""}
                  onChange={(value) => {
                    updateState("modules", value);
                  }}
                  isMulti
                  isPaginate={true}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      `${config.property_automate_api}/pasm/modules`,
                      { "x-api-key": config.api_key },
                      {},
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      moduleMaualResponse
                    )
                  }
                  isError={data?.error?.modules?.length > 0}
                  errorMessage={data?.error?.modules}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6} lg={6}>
              <SelectBox
                isRequired
                label="Select Billing Type"
                placeholder="Select Billing Type"
                value={data?.billing_type ?? ""}
                onChange={(value) => {
                  updateState("billing_type", value);
                }}
                options={billing_type}
                isError={data?.error?.billing_type?.length > 0}
                errorMessage={data?.error?.billing_type}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <SelectBox
                label="Select Add On"
                placeholder="Select Add On"
                value={data?.addOn ?? ""}
                onChange={(value) => {
                  updateState("addOn", value);
                }}
                isPaginate={true}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    `${config.api_rest}api/v1/addon/get/all`,
                    {},
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    maualResponse
                  )
                }
                isMulti
                options={list?.addOn}
                isError={data?.error?.addOn?.length > 0}
                errorMessage={data?.error?.addOn}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DatePickerNew
                label="Valid Till"
                placeholder="Select Valid Till"
                value={data?.valid_till}
                handleChange={(value) => {
                  updateState("valid_till", value);
                }}
                isrequired
                isError = {data?.error?.valid_till?.length > 0}
                errorMessage = {data?.error?.valid_till}
              />
            </Grid>
            <Grid item xs={0} md={6} lg={6}></Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography className={classes.label}>
                Customer Details
              </Typography>
              <CustomerCard data={data?.customers} />
              <PricingDetails
                plantittle={"Plan"}
                planename={data?.plan?.name}
                user={"Per User"}
                yearly={data?.billing_type?.label}
                planamount={data?.plan?.[data?.billing_type?.value]}
                addon={"Add-ons"}
                addno={data?.addOn?.length}
                Report={"Reports"}
                addamount={data?.plan?.[data?.billing_type?.value]}
                totalamount={
                  isNaN(
                    data?.plan?.[data?.billing_type?.value] +
                    data?.addOn
                      ?.map((i) => i?.[data?.billing_type?.price])
                      ?.reduce((a, b) => a + b, 0)
                  )
                    ? 0
                    : data?.plan?.[data?.billing_type?.value] +
                    data?.addOn
                      ?.map((i) => i?.[data?.billing_type?.price])
                      ?.reduce((a, b) => a + b, 0)
                }
                cuserDetailshide={false}
                heading={"New plan"}
              />
            </Grid>
          </Grid>

          <Box />
        </Box>
      </Box>
    </Box>
  );
};
