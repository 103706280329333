import { Box } from "@mui/material";
import { IntialState, returnEditPayload } from "./utiles";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import React from "react";
import { DetailsForm, AdminForm } from "./components";
import { useLocation } from "react-router-dom";
import {
  AlertProps,
  NetWorkCallMethods,
  ValidateEmail,
  ValidateWebsiteUrl,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { LoadingSection } from "../../components/loadingSection";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../router/routes";

export const CreateCustomers = () => {
  const [values, setValues] = React.useState({ ...IntialState });
  const { state } = useLocation();
  const history = useHistory();

  const alert = React.useContext(AlertContext);

  const handleValue = (key, val) => {
    let error = values.error;
    error[key] = "";
    setValues({ ...values, [key]: val });
  };

  const isValid = () => {
    let isValid = true;
    let error = values.error;
    if (values?.company_name === "") {
      isValid = false;
      error.company_name = "Please Enter company Name";
    } else {
      error.company_name = "";
    }
    if (values?.logo?.src === "") {
      isValid = false;
      error.logo = "Logo is Required";
    } else {
      error.logo = "";
    }
    if (values?.custom_name === "") {
      isValid = false;
      error.custom_name = "Please Enter custom Name";
    } else {
      error.custom_name = "";
    }

    if (ValidateEmail(values.company_mail_id) !== true) {
      isValid = false;
      error.company_mail_id = "Please Enter Company mailId";
    } else {
      error.company_mail_id = "";
    }

    if (ValidateWebsiteUrl(values.company_website) !== true) {
      isValid = false;
      error.company_website = "Please Enter valid Company Website url";
    } else {
      error.company_website = "";
    }

    if (values.address1 === "") {
      isValid = false;
      error.address1 = "Please Enter Address1";
    }

    if (values.address2 === "") {
      isValid = false;
      error.address2 = "Please Enter Address2";
    }

    if (values.landmark === "") {
      isValid = false;
      error.landmark = "Please Enter Landmark";
    }

    if (values.city === "") {
      isValid = false;
      error.city = "Please Enter City";
    }
    if (values.state === "") {
      isValid = false;
      error.state = "Please Enter State";
    }
    if (values.country === "") {
      isValid = false;
      error.country = "Please Enter Country";
    }

    if (values.pincode === "") {
      isValid = false;
      error.pincode = "Please Enter Pincode";
    }

    if (values.business_phone?.length !== 10) {
      isValid = false;
      error.business_phone = "Please Enter Buisness Phone (10 digit No)";
    } else {
      error.business_phone = "";
    }

    if (values.alternative_phone?.length !== 10) {
      isValid = false;
      error.alternative_phone = "Please Enter Alternate Phone (10 digit No)";
    } else {
      error.alternative_phone = "";
    }

    if (values?.admins?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please add Admin",
      });
    } else {
      let validated = []
      values?.admins?.forEach((e) => {
        let err = e?.error
        if (e?.admin_name?.length === 0) {
          err.admin_name = "Admin Name is required"
          isValid = false;
        } else {
          err.admin_name = ""
        }
        if (e?.admin_email_id?.length === 0) {
          err.admin_email_id = "Admin Email ID is required"
          isValid = false;
        } else {
          err.admin_email_id = ""
        }
        if (e?.admin_mobile?.length === 0) {
          err.admin_mobile = "Admin Mobile is required"
          isValid = false;
        } else {
          err.admin_mobile = ""
        }
        validated.push({ ...e, error: err })
      })
      values.admins = validated
    }
    setValues({ ...values, error });
    return isValid;
  };

  //create customers
  const create = () => {
    if (isValid()) {
      handleValue("loading", true);
      const payload = {
        customer_id: values?.customer_id ?? undefined,
        company_name: values?.company_name,
        client_details: {
          custom_domain: values?.custom_domain ?? undefined,
          custom_name: values?.custom_name,
        },
        business_phone: values?.business_phone,
        company_email_id: values?.company_mail_id,
        alternate_phone: values?.alternative_phone,
        company_website: values?.company_website,
        address_line_1: values?.address1,
        address_line_2: values?.address2,
        landmark: values?.landmark,
        city: values?.city,
        state: values?.state,
        country: values?.country,
        pincode: values?.pincode,
        admin_meta: values?.admins,
        is_active: values?.is_active,
        logo: values?.logo?.src,
      };

      NetworkCall(
        `${config.api_rest}api/v1/customer/${state?.isEdit ? "update" : "new"}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          handleValue("loading", false);

          if (state?.isEdit && values?.logo?.src) {
            const headers = { "x-api-key": config.api_key };

            let client_payload = {
              data: {
                logo_url: values?.logo?.src,
                subscription_id: values?.sub_id,
              },
            };
            NetworkCall(
              `${config.property_automate_api}/pasm/update_client`,
              NetWorkCallMethods.post,
              client_payload,
              headers,
              true,
              false
            )
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Customer ${state?.isEdit ? "Updated" : "Created"
              } Successfully`,
          });
          history.push(Routes.customers);
        })
        .catch((err) => {
          console.log(err);
          handleValue("loading", false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.err,
            msg: "Customer Creation Failed",
          });
        });
    }
  };
  //get customer details
  const getCustomerDetails = () => {
    handleValue("pageLoading", true);
    NetworkCall(
      `${config.api_rest}api/v1/customer/get?customer_id=${state?.data}`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    )
      .then((res) => {
        const result = returnEditPayload(res?.data?.data);
        setValues(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (state?.isEdit || state?.isView) {
      getCustomerDetails();
    }
  }, []);

  return (
    <Box>
      <TopNavBar
        title={`${state?.isEdit ? "Update" : "Create New"} Customers`}
        buttonLabel={state?.isEdit ? "Update" : "Create"}
        functions={create}
        back="/customers"
        isAction={false}
        disabled={values?.loading}
      />

      <Box p={2} height={`calc(100vh - 80px)`} sx={{ overflow: "auto" }}>
        {values?.pageLoading ? (
          <LoadingSection />
        ) : (
          <Box>
            <DetailsForm
              handleValue={handleValue}
              values={values}
              state={state}
            />
            <AdminForm
              update={(val) => handleValue("admins", val)}
              file={values?.admins}
              state={state}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
